import { ReactNode } from 'react';
import { StyledLabel } from './FormLabel.styled';

interface FormLabelProps {
  children?: ReactNode;
  htmlFor: string;
  label?: string;
  withExtraStyling?: boolean;
}

const FormLabel = ({ children, htmlFor, label, withExtraStyling = true }: FormLabelProps) => (
  <StyledLabel
    className="formGroup-label"
    dangerouslySetInnerHTML={label ? { __html: label } : undefined}
    htmlFor={htmlFor}
    withExtraStyling={withExtraStyling}
  >
    {children}
  </StyledLabel>
);

export default FormLabel;
