export const LAYOUT_TYPES = {
  FILL: 'fill',
  FIXED: 'fixed',
  INTRINSIC: 'intrinsic',
  RESPONSIVE: 'responsive',
} as const;
export type LayoutType = (typeof LAYOUT_TYPES)[keyof typeof LAYOUT_TYPES];

export const MESSAGE_POSITION = {
  AFTER: 'after',
  BEFORE: 'before',
} as const;
export type MessagePosition = (typeof MESSAGE_POSITION)[keyof typeof MESSAGE_POSITION];

export const IMAGE_FORMATS = {
  WEBP: 'webp',
} as const;
export type ImageFormat = (typeof IMAGE_FORMATS)[keyof typeof IMAGE_FORMATS];
