import { Announcement as AnnouncementType } from 'types/Announcement';
import Announcement from './Announcement/Announcement';
import { AnnouncementBarWrapper } from './AnnouncementBar.styled';

interface Props {
  announcements: AnnouncementType[];
}

const AnnouncementBar = ({ announcements = [] }: Props) => {
  if (!announcements?.length) {
    return null;
  }

  return (
    <AnnouncementBarWrapper id="announcementsBar">
      {announcements.map((announcement) => (
        <Announcement key={announcement.code} announcement={announcement} />
      ))}
    </AnnouncementBarWrapper>
  );
};

export default AnnouncementBar;
