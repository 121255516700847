import styled, { css } from 'styled-components';
import { color, fontSize, spacer, variable } from 'theme';

interface StyledLabelProps {
  withExtraStyling?: boolean;
}

export const StyledLabel = styled.label<StyledLabelProps>`
  margin: 0px;

  .formGroup-type-text & {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .appended & {
    padding-right: ${spacer(300)} !important;
  }

  .prepended & {
    padding-left: ${spacer(300)} !important;
  }

  ${({ withExtraStyling }) =>
    withExtraStyling &&
    css`
      border: ${variable('border-transparent')};
      color: ${color('whisper-300')};
      left: 0;
      top: 0;
      line-height: 1.5;
      padding: ${spacer(100)};
      pointer-events: none;
      position: absolute;
      transform: translate(0, 0);
      transition: font-size 0.1s ease-out, transform 0.1s ease-out;
      width: 100%;
      z-index: 1;

      .dirty &,
      .active &,
      .initial & {
        font-size: ${fontSize(75)};
        transform: translate(0, -0.75rem);
      }

      .invalid & {
        color: ${color('love-400')};
      }

      .valid & {
        color: ${color('ocean-400')};
      }
    `}
`;
