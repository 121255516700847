import styled from 'styled-components';
import { color } from 'theme';
import { zIndex } from '../../../theme/functions';
import Box from '../../Box/Box';

export const AnnouncementBarWrapper = styled(Box)`
  position: relative;
  background-color: ${color('whisper-100')};
  z-index: ${zIndex('header')};
`;
