import styled, { css } from 'styled-components';
import { spacer, breakpointUp, color } from 'theme';
import { BREAKPOINTS } from '../../../../constants/breakpoints';

export const StyledAnnouncement = styled.div`
  display: flex;
  padding: ${spacer(50)};
  justify-content: space-between;
  align-items: center;

  ${breakpointUp(
    BREAKPOINTS.LG,
    css`
      padding: ${spacer(100)};
    `
  )};

  // Selecting HTML elements since this is CMS content
  p {
    display: inline-block;
    margin-bottom: 0px;
  }

  a {
    color: ${color('primary-300')};
    text-decoration: none;
    display: flex;
    gap: ${spacer(50)};
  }
`;

export const StyledAnnouncementTextSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      flex-direction: row;
    `
  )};
  ${breakpointUp(
    BREAKPOINTS.LG,
    css`
      justify-content: center;
    `
  )};
`;
