import styled from 'styled-components';
import {
  spacer,
  marginMapper,
  paddingMapper,
  positionMapper,
  variable,
  MarginProps,
  PaddingProps,
  PositionProps,
} from 'theme';
import { FontType, Border, Spacer, DisplayType } from 'constants/styling';
import { ReactNode } from 'react';

export interface StyledBoxProps extends PositionProps, PaddingProps, MarginProps, React.HTMLAttributes<HTMLDivElement> {
  as?: FontType;
  border?: Border;
  borderRadius?: Spacer;
  children?: ReactNode;
  className?: string;
  display?: DisplayType;
  fullHeight?: boolean;
  fullWidth?: boolean;
}

export default styled.div<StyledBoxProps>`
  ${marginMapper}
  ${paddingMapper}
  ${positionMapper}

  height: ${({ fullHeight }) => fullHeight && '100%'};
  width: ${({ fullWidth }) => fullWidth && '100%'};
  border-radius: ${({ borderRadius }) => spacer(borderRadius)};
  ${({ border }) => border === 'default' && variable('border')};
  ${({ border }) => border === 'dashed' && variable('border-dashed')};
  display: ${({ display: displayProp }) => displayProp && displayProp};
`;
