import styled, { css } from 'styled-components';
import { color, fontSize, spacer, variable } from 'theme';

export const StyledFormGroup = styled.div`
  margin-bottom: ${spacer(75)};
  position: relative;

  /* clears the 'X' from Internet Explorer */
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
`;

export const StyledFromGroupResponse = styled.div`
  font-size: ${fontSize(75)};
  margin-top: ${spacer(25)};

  .invalid & {
    color: ${color('love-400')};
  }

  .valid & {
    color: ${color('ocean-400')};
  }
`;

export const StyledFormGroupType = styled.div`
  flex: 1 1 100%;
  position: relative;
`;

export const StylingFormGroupSelectAndInput = css`
  ${variable('border')}
  border-radius:${variable('border-radius')};
  padding: ${spacer(125)} ${spacer(100)} ${spacer(75)};
  background-color: ${color('white')};
  width: 100%;
  line-height: 1.5;

  &[disabled] {
    background-color: ${color('whisper-200')};
  }

  .appended & {
    padding-right: ${spacer(300)} !important;
  }

  .prepended & {
    padding-left: ${spacer(300)} !important;
  }

  &:focus,
  &:active,
  .active & {
    border-color: ${color('primary-300')};
    box-shadow: ${variable('box-shadow-soft')};
  }

  .invalid & {
    background-color: ${color('love-transparent-100')};
    border-color: ${color('love-300')};
  }

  .valid & {
    background-color: ${color('ocean-transparent-100')};
    border-color: ${color('ocean-300')};
  }
`;
