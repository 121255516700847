import { BREAKPOINTS } from 'constants/breakpoints';
import { LAYOUT_TYPES } from 'constants/image';

export type Image = {
  alt?: string;
  code?: string;
  height?: number;
  id?: string;
  src?: string;
  type?: string;
  url?: string;
  width?: number;
};
export type StaticImage = {
  code?: string;
  url?: string;
};

// "Image" in BackOffice
export type ImageData = {
  altText?: string;
  code: string;
  format?: string;
  galleryIndex?: number;
  imageType?: 'PRIMARY' | 'GALLERY';
  url?: string;
};

export type LayoutType = (typeof LAYOUT_TYPES)[keyof typeof LAYOUT_TYPES];

export const DEFAULT_SIZE_KEY = 'default';

export type ImageSizes = {
  [BREAKPOINTS.XXL]?: number;
  [BREAKPOINTS.XL]?: number;
  [BREAKPOINTS.LG]?: number;
  [BREAKPOINTS.MD]?: number;
  [BREAKPOINTS.SM]?: number;
  [DEFAULT_SIZE_KEY]: number;
};

export type BackofficeImage = {
  altText?: string;
  code: string;
  format?: string;
  galleryIndex?: number;
  imageType?: 'PRIMARY' | 'GALLERY';
  url?: string;
};
