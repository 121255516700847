import { forwardRef } from 'react';
import StyledBox, { StyledBoxProps } from './Box.styled';

export type BoxProps = StyledBoxProps;

const Box = forwardRef<HTMLDivElement, BoxProps>(
  ({ as = 'div', children, display = 'block', fullHeight = false, fullWidth = false, ...props }, ref) => (
    <StyledBox ref={ref} as={as} display={display} fullHeight={fullHeight} fullWidth={fullWidth} {...props}>
      {children}
    </StyledBox>
  )
);

export default Box;
