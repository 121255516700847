/* eslint-disable sort-keys-fix/sort-keys-fix */
/**
 * File that contains localized static pages
 */

const staticPages: Record<string, Record<string, string>> = {
  services: {
    nl: 'diensten',
    fr: 'service',
    de: 'service',
    en: 'service',
  },
  faq: {
    nl: 'faq',
    fr: 'faq',
    de: 'faq',
    en: 'faq',
  },
  priceGuarantee: {
    nl: 'prijs-garantie',
    fr: 'garantie-de-prix',
    de: 'bestpreis-garantie',
    en: 'price-guarantee',
  },
  cashbacks: {
    nl: 'cashbacks',
    fr: 'cashbacks',
    de: 'cashbacks',
    en: 'cashbacks',
  },
  warranty: {
    nl: 'garantie',
    fr: 'garantie',
    de: 'garantie',
    en: 'warranty',
  },
  cookiePolicy: {
    nl: 'cookie-policy',
    fr: 'cookie-policy',
    de: 'cookie-policy',
    en: 'cookie-policy',
  },
  privacyPolicy: {
    nl: 'privacy-policy',
    fr: 'privacy-policy',
    de: 'privacy-policy',
    en: 'privacy-policy',
  },
  termsOfService: {
    nl: 'verkoopsvoorwaarden',
    fr: 'conditions-de-vente',
    de: 'verkaufsbedingungen',
    en: 'terms-of-service',
  },
  termsOfServiceFreeDelivery: {
    nl: 'gratis-levering',
    fr: 'livraison-offerte',
    de: 'kostenlose-lieferung',
    en: 'free-delivery',
  },
  termsOfServiceProductReturn: {
    nl: 'herroepingsrecht',
    fr: 'retourner',
    de: 'rueckgaberecht',
    en: 'returns',
  },
  extendedGuarantee: {
    nl: 'verlengde-garantie',
    fr: 'garantie-prolongee',
    de: 'garantieverlaengerung',
    en: 'extended-guarantee',
  },
  termsOfServiceComplaints: {
    nl: 'klachtenprocedure',
    fr: 'procedure-de-plainte',
    de: 'eine-beschwerde-erheben',
    en: 'terms-of-service-complaints',
  },
  weddingGiftList: {
    nl: 'huwelijkslijst',
    fr: 'liste-de-mariage',
    de: 'hochzeitsliste',
    en: 'wedding-gift-list',
  },
  ecoCheques: {
    nl: 'ecocheques',
    fr: 'ecocheques',
    de: 'ecocheques',
    en: 'ecocheques',
  },
  paySafely: {
    nl: 'veilig-bestellen',
    fr: 'commander-en-toute-securite',
    de: 'sicherer-zahlungsverkehr-bei-hifi-international',
    en: 'pay-safely',
  },
  aboutUs: {
    nl: 'over-ons',
    fr: 'notre-histoire',
    de: 'ueber-hifi-international',
    en: 'about-us',
  },
  disclaimer: {
    nl: 'disclaimer',
    fr: 'disclaimer',
    de: 'disclaimer',
    en: 'disclaimer',
  },
  customerService: {
    nl: 'contact',
    fr: 'contact',
    de: 'kontakt',
    en: 'contact',
  },
  b2b: {
    nl: 'b2b',
    fr: 'b2b',
    de: 'b2b',
    en: 'b2b',
  },
};

export default staticPages;
