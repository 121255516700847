import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react';
import { cn } from 'utils/cn';

type Props = React.ForwardRefExoticComponent<
  {
    className?: string;
  } & React.RefAttributes<HTMLDivElement>
>;

const Skeleton = forwardRef<ElementRef<Props>, ComponentPropsWithoutRef<Props>>(({ className, ...props }, ref) => (
  <div className={cn('animate-pulse rounded bg-accent-20', className)} ref={ref} {...props} />
));

Skeleton.displayName = 'Skeleton';

export { Skeleton };
