import { ReactNode } from 'react';
import { StyledFormGroup } from './FormGroup.styled';

interface FormGroupProps {
  children: ReactNode;
  className?: string;
}

const FormGroup = ({ children, className }: FormGroupProps) => (
  <StyledFormGroup className={`formGroup ${className}`}>{children}</StyledFormGroup>
);

export default FormGroup;
