import { LAYOUT_TYPES } from 'constants/image';
import { ObjectPosition } from 'constants/styling';
import { ImageProps as NextImageProps } from 'next/legacy/image';
import { ImageSizes } from 'types/Image';
import { getImageSizes, imageLoader, isImageSVG } from 'utils/imageUtil';
import { StyledNextImage } from './Image.styled';

interface ImageProps extends Omit<NextImageProps, 'sizes' | 'src'> {
  objectPosition?: ObjectPosition;
  sizes?: ImageSizes;
  src: string;
}

const Image = ({
  alt = 'Image Alt',
  height = 0,
  layout = LAYOUT_TYPES.INTRINSIC,
  objectFit = 'contain',
  objectPosition = 'center',
  quality = 75,
  sizes: initialSizes,
  src,
  width = 0,
  ...props
}: ImageProps) => {
  if (!src) {
    return null;
  }

  const addSizes = layout === LAYOUT_TYPES.FILL;
  const addFixedSizes =
    layout === LAYOUT_TYPES.INTRINSIC || layout === LAYOUT_TYPES.FIXED || layout === LAYOUT_TYPES.RESPONSIVE;
  const sizes = (addSizes ? getImageSizes(initialSizes) : undefined) ?? undefined;

  return (
    // TODO: Refactor to new Image component
    <StyledNextImage
      alt={alt}
      data-ot-ignore
      height={addFixedSizes ? height : undefined}
      layout={layout}
      loader={imageLoader}
      objectFit={objectFit}
      objectPosition={objectPosition}
      quality={quality}
      sizes={sizes}
      src={src}
      unoptimized={isImageSVG(src)}
      width={addFixedSizes ? width : undefined}
      {...props}
    />
  );
};

export default Image;
