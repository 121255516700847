import { useWebConfig } from 'features/configuration/queries';
import useRouter from 'hooks/useRouter';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import type { Announcement as AnnouncementType } from 'types/Announcement';
import config from '../../../../config';
import { ICON_NAMES } from '../../../../constants/icon';
import { hasAnnouncements, showAnnouncement } from '../../../../utils/pageUtil';
import { uppercaseFirstLetter } from '../../../../utils/textUtil';
import Icon from '../../../Icon/Icon';
import Typography from '../../../Typography/Typography';
import { StyledAnnouncement, StyledAnnouncementTextSection } from './Announcement.styled';

const { platform } = config;

const BODY_CLASS = 'with-announcement';

interface Props {
  announcement: AnnouncementType;
}

const Announcement = ({ announcement }: Props) => {
  const router = useRouter();
  const { data: webConfig } = useWebConfig();

  const [cookies, setCookie] = useCookies();
  const [isVisible, setIsVisible] = useState(hasAnnouncements(cookies, [announcement], router));

  useEffect(() => {
    const showMessage = showAnnouncement(router, announcement?.pageTypes);
    const body = document.querySelector('body');
    if (showMessage && isVisible && announcement?.message && body) {
      if (!body?.classList.contains(BODY_CLASS)) {
        body.classList.add(BODY_CLASS);
      }
    }

    return () => {
      const body = document.querySelector('body');
      if (body) {
        body.classList.remove(BODY_CLASS);
      }
    };
  }, []);

  const onClose = (announcementCode: string) => {
    let currentAnnouncement = cookies[config.announcements.key];

    if (typeof currentAnnouncement === 'string' && !currentAnnouncement.includes(announcementCode)) {
      currentAnnouncement = `${currentAnnouncement}, ${announcementCode}`;
    } else {
      currentAnnouncement = announcementCode;
    }

    setCookie(config.announcements.key, currentAnnouncement, {
      ...config.cookie,
      maxAge: config.announcements.expirationTime,
    });

    setIsVisible(false);

    const hasOtherAnnouncements = hasAnnouncements(cookies, webConfig?.announcements, router);
    if (!hasOtherAnnouncements) {
      const body = document.querySelector('body');
      if (body) {
        body.classList.remove(BODY_CLASS);
      }
    }
  };

  const updateAnnouncementBodyClass = () => {
    const showMessage = hasAnnouncements(cookies, webConfig?.announcements, router);

    if (typeof document !== 'undefined') {
      const body = document.querySelector('body');
      if (!body) return;
      if (!body.classList?.contains(BODY_CLASS) && showMessage) {
        body.classList.add(BODY_CLASS);
      } else if (body.classList?.contains(BODY_CLASS) && !showMessage) {
        body.classList.remove(BODY_CLASS);
      }
    }
  };

  const showMessage = showAnnouncement(router, announcement?.pageTypes);

  updateAnnouncementBodyClass();

  if (!showMessage || !isVisible || !announcement?.message) {
    return null;
  }

  return (
    <StyledAnnouncement id={`${uppercaseFirstLetter(platform?.toLowerCase())}AnnouncementBar`}>
      <StyledAnnouncementTextSection>
        <Typography color="black" fontWeight="bold" html={announcement.message} marginBottom={0} type="p" />

        {announcement.closingButton && (
          <div className="announcement-btn" dangerouslySetInnerHTML={{ __html: announcement.closingButton }} />
        )}
      </StyledAnnouncementTextSection>

      <div onClick={() => onClose(announcement.code)} role="button" tabIndex={0}>
        <Icon type="custom" name={ICON_NAMES.TIMES} size={100} />
      </div>
    </StyledAnnouncement>
  );
};

export default Announcement;
