import useRouter from 'hooks/useRouter';
import { ReactNode } from 'react';
import { getLocalizedRoute } from '../../../../utils/localizedRouteUtil';
import Link from '../../../Link/Link';

interface LocalizedLinkProps {
  as?: string;
  children?: ReactNode;
  className?: string;
  name: string;
  newTab?: boolean;
  options?: object;
  replaceUrl?: boolean;
  shouldScrollToTop?: boolean;
}

export const LocalizedLinkComponent = ({
  children,
  className,
  name,
  newTab,
  options,
  replaceUrl,
  shouldScrollToTop,
}: LocalizedLinkProps) => {
  const router = useRouter();
  const { locale } = router;

  const localizedPath = getLocalizedRoute(name, locale, options);

  return (
    <Link className={className} replaceUrl={replaceUrl} scroll={shouldScrollToTop} url={localizedPath} newTab={newTab}>
      {children}
    </Link>
  );
};

export default LocalizedLinkComponent;
