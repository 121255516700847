import useRouter from 'hooks/useRouter';
import { ReactNode } from 'react';
import StaticPageTranslations from '../../../../i18n/staticPages';
import Link from '../../../Link/Link';

interface CMSPageLinkProps {
  children?: ReactNode;
  className?: string;
  newTab?: boolean;
  page: string;
}

const CMSPageLink = ({ children, className, newTab, page }: CMSPageLinkProps) => {
  const router = useRouter();
  const { locale } = router;

  const slug = StaticPageTranslations[page][locale];

  if (!slug) return null;

  return (
    <Link className={className} url={`/${slug}` || '/'} newTab={newTab}>
      {children}
    </Link>
  );
};

export default CMSPageLink;
