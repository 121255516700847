import breakpointValues from 'theme/variables/breakpoints';
import { Breakpoint } from 'types/Breakpoint';
import { DEFAULT_SIZE_KEY, ImageSizes } from 'types/Image';
import { IMAGE_FORMATS } from '../constants/image';
import { log } from './loggerUtil';
import { removeWhiteSpaces } from './textUtil';

const OPTIMIZED_URL_KEYWORDS = ['media.dev', 'media.stg', 'media.krefel', 'media.hifi', 'media.tones'];

export const isImageSVG = (url: string) => {
  const extension = url.split('.')[url.split('.').length - 1];

  return extension.toUpperCase() === 'SVG';
};

export const isContentfulImage = (url: string) => url.includes('ctfassets');

export const removeFormatFromUrl = (url: string) => {
  const regexWithSize = /((.*)\/(\d+)x(\d+))\.((.*)\.(.*))/;
  const match = url.match(regexWithSize);

  if (match?.[3] && match?.[4]) {
    const removedFormat = url.split(`${match[3]}x${match[4]}.`);

    return removedFormat.join('');
  }

  return url;
};

export const getUrlWithFormat = (imageUrl: string, width?: string | number) => {
  if (isContentfulImage(imageUrl)) {
    if (imageUrl.startsWith('//')) {
      imageUrl = `https:${imageUrl}`;
    }
    try {
      const ContentfulUrl = new URL(imageUrl);
      ContentfulUrl.searchParams.append('fm', 'webp');
      return ContentfulUrl.toString();
    } catch (error) {
      log('imageUtil/getUrlWithFormat', 'Invalid Contenful url', imageUrl);
      return imageUrl;
    }
  }
  const url = removeFormatFromUrl(imageUrl);
  const splitUrl = url.split('/');
  const imageName = splitUrl[splitUrl.length - 1].split('.')[0];

  const shouldOptimizeImage = OPTIMIZED_URL_KEYWORDS.some((keyword) => url.includes(keyword));

  if (!shouldOptimizeImage) return url;

  if (width === undefined) {
    splitUrl[splitUrl.length - 1] = `${imageName}.${IMAGE_FORMATS.WEBP}`;
  }

  if (width !== undefined && splitUrl.length > 0) {
    splitUrl[splitUrl.length - 1] = `${width}x${width}.${imageName}.${IMAGE_FORMATS.WEBP}`;
  }

  return splitUrl.join('/');
};

/**
 * Function which returns a string of sizes to accompany the image optimization
 * @param {ImageSizes} sizes - the sizes object containing all max widths per media query
 * @return {string} The flat string of media queries
 * @example
    Input:
    sizes={{
      xl: 1000,
      default: 100, // REQUIRED
    }}

     Output:
    `(min-width: 1200px) 1000px, 100px`
 */
export const getImageSizes = (sizes?: ImageSizes) => {
  if (!sizes) {
    return undefined;
  }

  // Size mediaqueries need to be sorted in descending size order
  // This is because the browser picks the size from the first matching media query
  /* eslint-disable sort-keys-fix/sort-keys-fix */
  const sortedSizes: ImageSizes = {
    xxl: sizes.xxl,
    xl: sizes.xl,
    lg: sizes.lg,
    md: sizes.md,
    sm: sizes.sm,
    default: sizes.default,
  };
  /* eslint-enable sort-keys-fix/sort-keys-fix */

  const values = Object.entries(sortedSizes).map(([key, value]) => {
    if (value) {
      const mediaQuery = key !== DEFAULT_SIZE_KEY ? `(min-width: ${breakpointValues[key as Breakpoint]}px)` : '';
      return removeWhiteSpaces(`${mediaQuery} ${value}px`);
    }

    return null;
  });

  return values.filter((v) => !!v).join(', ');
};

export const imageLoader = ({ src, width }: { src: string; width: number | string }) => getUrlWithFormat(src, width);
